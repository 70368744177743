import Send from '@/Send.js'

export default {
  getOrderStatus() {
    return Send({
      url: '/api/common/v1/common_code/list/order_status_cd',
      method: 'get',
    })
  },

  getReturnStatus() {
    return Send({
      url: '/api/common/v1/common_code/list/order_return_status_cd',
      method: 'get',
    })
  },

  getManager() {
    return Send({
      url: '/api/seller/v1/manager/list',
      method: 'post',
    })
  },

  getNoOwnerManager() {
    return Send({
      url: '/api/seller/v1/manager/list/except',
      method: 'post',
    })
  },

  getProducer() {
    return Send({
      url: '/api/seller/v1/manager/current',
      method: 'post',
    })
  },

  getStore() {
    return Send({
      url: '/api/seller/v1/correspondent/list',
      method: 'post',
      data: { storeName: '' },
    })
  },

  getStoreSearch(data) {
    return Send({
      url: '/api/seller/v1/correspondent/list',
      method: 'post',
      data: data,
    })
  },

  getStoreSearchMe(data) {
    return Send({
      url: '/api/seller/v1/correspondent/list/me',
      method: 'post',
      data: data,
    })
  },

  getKind() {
    return Send({
      url: '/api/common/v1/wine_code/list/WINE_GOOD_KIND_CD',
      method: 'get',
    })
  },

  getPlace() {
    return Send({
      url: '/api/common/v1/wine_code/list/WINE_GOOD_PLACE_CD',
      method: 'get',
    })
  },

  getVareitiy() {
    return Send({
      url: '/api/common/v1/wine_code/list/WINE_GOOD_VARIETY_CD',
      method: 'get',
    })
  },

  getNose() {
    return Send({
      url: '/api/common/v1/wine_code/list/WINE_GOOD_NOSE_CD',
      method: 'get',
    })
  },

  getPairing() {
    return Send({
      url: '/api/common/v1/wine_code/list/WINE_GOOD_PAIRING_CD',
      method: 'get',
    })
  },

  getLiquorKind() {
    return Send({
      url: '/api/common/v1/wine_code/list/WINE_GOOD_LIQUOR_KIND_CD',
      method: 'get',
    })
  },

  getPalate() {
    return Send({
      url: '/api/common/v1/wine_code/list/WINE_GOOD_PALATE_CD',
      method: 'get',
    })
  },

  getStoreType() {
    return Send({
      url: '/api/common/v1/common_code/list/store_type_cd',
      method: 'get',
    })
  },

  getTaxBillType() {
    return Send({
      url: '/api/common/v1/common_code/list/order_tax_bill_status_cd',
      method: 'get',
    })
  },

  getDepositType() {
    return Send({
      url: '/api/common/v1/common_code/list/deposit_type_cd',
      method: 'get',
    })
  },

  getStockType() {
    return Send({
      url: '/api/common/v1/common_code/list/wine_stock_history_cd',
      method: 'get',
    })
  },

  getBank() {
    return Send({
      url: '/api/common/v1/common_code/list/bank_code',
      method: 'get',
    })
  },
  getSearchVarietyList(keyword) {
    return Send({
      "url": `/api/common/v1/wine_code/variety/search?keyword=${keyword}`,
      "method": "GET",
    })
  },
  getVarietyList() {
    return Send({
      "url": "/api/common/v1/wine_code/variety/list",
      "method": "GET",
    })
  }
}
