import Send from '@/Send.js'

export default {
  login(data) {
    return Send({
      method: 'post',
      url: '/api/seller/v1/user/login',
      data: data,
    })
  },

  logout() {
    return Send({
      method: 'get',
      url: '/api/seller/v1/user/logout',
    })
  },

  reset(data) {
    return Send({
      method: 'post',
      url: '/api/seller/v1/user/findPwd',
      data: data,
    })
  },

  checkEmail(data) {
    return Send({
      method: 'post',
      url: '/api/seller/v1/user/requestEmailAuthCode',
      data: data,
    })
  },

  findPwd(data) {
    return Send({
      method: 'post',
      url: '/api/seller/v1/user/findPwd',
      data: data,
    })
  },

  changePwd(data) {
    return Send({
      method: 'post',
      url: '/api/seller/v1/user/updatePwd',
      data: data,
    })
  },

  checkCode(data) {
    return Send({
      method: 'post',
      url: '/api/seller/v1/user/checkEmailAuthCode',
      data: data,
    })
  },

  register(data) {
    return Send({
      method: 'post',
      url: '/api/seller/v1/user/join',
      data: data,
    })
  },

  update(data) {
    return Send({
      url: '/api/seller/v1/manager/master/modify',
      method: 'post',
      data: data,
    })
  },
}
