import Send from '@/Send.js'

export default {
  getPopupData() {
    return Send({
      method: 'get',
      url: '/api/seller/v1/banner/popup/one',
    })
  },
  PopupClose(data) {
    return Send({
      method: 'get',
      url: '/api/seller/v1/banner/popup/close/' + data,
    })
  },
  PopupNoSee(data) {
    return Send({
      method: 'get',
      url: '/api/seller/v1/banner/popup/nosee/' + data,
    })
  },
  PopupClick(data) {
    return Send({
      method: 'get',
      url: '/api/seller/v1/banner/popup/click/' + data,
    })
  },
  PopupPV(data) {
    return Send({
      method: 'get',
      url: '/api/seller/v1/banner/popup/pv/' + data,
    })
  }
}
