import { useStore } from "vuex";
import {defineComponent, h, onMounted, ref, resolveComponent, computed} from "vue";
import { RouterLink, useRoute } from 'vue-router'

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue'
import nav from '@/_nav.js'

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute()
    const firstRender = ref(true)

    onMounted(() => {
      firstRender.value = false
    })

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child)),
            }),
          },
          {
            togglerContent: () => [
              // h(resolveComponent('CIcon'), {
              //   customClassName: 'nav-icon',
              //   name: item.icon,
              // }),
              item.name,
            ],
            default: () => item.items.map((child) => renderItem(child)),
          },
        )
      }
      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                    href: props.href,
                    onClick: (event) => {
                      if (item.name === '세금계산서 등록' || item.name === '세금계산서 전송') {
                        event.preventDefault()
                        alert("현재 업데이트 중인 기능입니다. 마켓뱅 문의 > 카카오 비즈니스링크 (http://pf.kakao.com/_xcUYxnxj/chat)")
                      } else {
                        props.navigate()
                      }
                    },
                    disabled:  item.name === '세금계산서 등록' || item.name === '세금계산서 전송', // 세금계산서 등록, 전송 비활성화
                  },
                  {
                    default: () => [
                      // item.icon &&
                      //   h(resolveComponent('CIcon'), {
                      //     customClassName: 'nav-icon',
                      //     name: item.icon,
                      //   }),
                      item.name,
                      item.badge &&
                        h(
                          CBadge,
                          {
                            class: 'ms-auto',
                            color: item.badge.color,
                          },
                          {
                            default: () => item.badge.text,
                          },
                        ),
                    ],
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            },
          )
    }

    return ()=>h(CSidebarNav, {}, {
        "default" : ()=>{
          const store = useStore();
          const user_info = computed(()=>store.state.user_info);
          const return_arr = [];
          nav.forEach(( list )=>{
            if(user_info.value.managerRole === "ROLE_SELLER_MANAGER" &&
              list.name === "계정 관리"){
                for(let t = 0; t < list.items.length; t++){
                  if(list.items[t].to.indexOf("/account/info") === -1) continue;
                  list.items.splice(t, 1);
                }
                return_arr.push( renderItem( list ) );
                return;
            }else if(user_info.value.managerRole === "ROLE_SELLER_OWNER" &&
              list.name === "계정 관리"){
              if(list.items.length === 2){
                list.items.push({"component" : "CNavItem", "name" : "계정 정보 변경","to" : "/account/info"});
              }
            }
            return_arr.push(renderItem(list))
          });
          return return_arr;
        },
      },
    )
  },
})
export { AppSidebarNav }
