<template>
<article>
<h2>제1장 총칙</h2>

<h3>제1조 목적</h3>

<p>이 약관은 쓰리랩스 주식회사(“회사”)가 제공하는 마켓뱅(Marketbang) 서비스에 관한 회사와 이용자의 권리, 의무 기타 필요한 사항을 규정함을 목적으로 합니다.</p>

<h3>제2조 용어의 정의</h3>

<p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 조에서 정의되지 않은 용어들은 일반의 상식적인 해석에 따릅니다.</p>
<ol>
<li>①	<span>“마켓뱅(Marketbang)”</span>란 회사가 주류 발주 플랫폼 서비스를 제공하기 위해 제작하여 사용하는 브랜드를 말합니다.</li>
<li>②	<span>“마켓뱅서비스”</span> 또는 <span>“서비스”</span>란 회사가 회원들이 주류 발주 (거래)를 위해 또는 주류에 관한 정보를 얻기 위해 상호 소통할 수 있도록 제공하는 인터넷 사이트(이에 한정되지 않음) 등 인터넷 공간 및 이와 관련하여 제공하는 일체의 서비스를 의미하 고, 회사가 서비스를 위해 오프라인 공간을 제공하는 경우 위 공간에서 제공하는 서비스도 포함합니다.</li>
<li>③	<span>“이용자”</span>란 인터넷사이트 또는 모바일앱을 통해 접속하여 회사가 제공하는 서비스를 이용하는 자로, 회원과 비회원으로 구분합니다.</li>
<li>④	<span>“회원”</span>이란 이 약관에서 정하는 바에 따라 회원으로 가입하여 서비스를 이용하는 모든 고객을 의미합니다. 회사는 원활한 서비스를 제공하기 위해 회원의 종류를 구분하여 서비스 내용을 달리 정할 수 있고, 필요에 따라 이를 변경할 수 있습니다.</li>
<li>⑤	<span>“비회원”</span>이란 회원가입절차를 거치지 않고 서비스(비회원에게도 제공되는 서비스에 한함)를 열람 및 이용하는 자를 말합니다. 회사는 회원에 대한 서비스 제공 향상을 위해 비회원에 대한 서비스 제공 범위를 변경, 제한하거나, 종료할 수 있습니다.</li>
<li>⑥	<span>“판매자”</span> 또는 <span>“판매자 회원”</span>란 회사가 제공하는 서비스를 이용하여 자신의 상품등을 판매하는 자를 의미하며, 회사로부터 판매대행, 광고서비스등을 제공받는 자를 말합니다. </li>
<li>⑦	<span>“구매자”</span> 또는 <span>“구매자 회원”</span>란 회사가 제공하는 서비스를 이용하여 상품을 구매하는 자를 의미하며 관련법령에 따라 본 서비스를 이용할 자격을 갖추고 회원가입 절차를 완료한 자를 말합니다.</li>
<li>⑧	<span>“아이디”</span>란 이용자의 식별과 관리 및 회원가입을 위하여 이용자가 제공하고 회사가 승인한 이용자 본인의 정보(이메일 주소, SNS아이디 등을 포함)를 말합니다.</li>
<li>⑨	<span>“비밀번호”</span>란 회원의 본인 여부를 검증하고 회원의 개인정보를 보호하기 위해 회원이 스스로 설정하여 회사에 등록하는 고유의 문자와 숫자, 특수문자등의 조합을 말합니다. 회사는 보안 강화를 위해 알파벳과 숫자의 조합 등 비밀번호 설정 요건을 지정할 수 있습니다.</li>
<li>⑩	<span>“인터넷사이트”</span>란 회사가 마켓뱅서비스 안내 및 제공, 이용자 관리 등을 위해 개설한 인터넷사이트를 말합니다.</li>
<li>⑪	<span>“콘텐츠”</span>란 이용자가 서비스를 이용함에 있어 서비스 상에 게시한 부호, 문자, 음성, 음향, 이미지, 영상, 각종파일과 링크 등을 의미 합니다. </li>
</ol>


<h3>제3조 약관의 효력발생 및 해석</h3>
<ol>
<li>①	이 약관은 이용자의 동의에 의해 효력이 발생합니다. 회원의 경우 가입하는 과정에서 약관 동의 절차를 거치게 되고, 비회원의 경우 회사의 인터넷사이트 또는 서비스를 이용하는 동안 별도의 공지나 통지 없이도 이 약관에 동의한 것으로 간주됩니다.</li>
<li>②	회사는 서비스 정책상 필요한 경우 특정 회원이나 서비스에 관한 개별약관을 별도로 제정하여 게시할 수 있으며 이 경우 제1항이 준용됩니다. 해당 서비스 또는 회원에 관하여 이 약관과 개별약관의 내용이 상충하는 경우에는 개별약관이 우선 적용됩니다.</li>
<li>③	이 약관에서 정하지 아니한 사항에 대해서는 관계법령 및 상관례에 따릅니다.</li>
</ol>

<h3>제4조 약관의 게시와 개정</h3>
<ol>
<li>①	회사는 이 약관을 인터넷사이트에 게시하여 공지합니다. 다만 약관 전문은 이용자가 연결화면을 통하여 보는 방법으로 게시할 수 있습니다.</li>
<li>②	회사는 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
<li>③	회사가 약관을 개정하는 경우 개정내용과 적용일자를 명시하여 적용일 7일 전부터 적용일 후 7일째 되는 날까지 제1항의 방법으로 공지합니다. 다만 이용자에게 불리한 개정인 경우 적용일 30일 전부터 적용일 전일까지 공지하고 이용자가 기재한 이메일주소 또는 마켓뱅 인터넷사이트 내 팝업창 등의 전자적 수단을 통해 별도로 고지 합니다. </li>
<li>④	회사가 본 조에 따라 개정약관을 공지하면서 일정한 기간 내에 부동의 의사표시를 하지 않으면 승인한 것으로 본다는 뜻을 회원에게 공지, 고지하였음에도 회원이 명시적인 부동의 의사표시를 하지 않는 경우에는 개정약관을 동의한 것으로 봅니다. </li>
<li>⑤	회원이 개정약관에 동의하지 않는 경우에는 개정약관의 적용일 이전에 명시적인 부동의 의사표시를 하고 이용계약을 해지할 수 있습니다.</li>
<li>⑥	비회원에게는 제4항 및 제5항에서 정하는 절차 없이 개정약관 적용일부터 개정약관이 적용됩니다. </li>
<li>⑦	이용자는 약관 변경에 대하여 주의의무를 다하여야 하며, 회사는 본 조의 절차에 따라 약관을 개정하였음에도 이용자가 개정약관을 인지하지 못하여 발생하는 손해에 대해서는 책임을 지지 않습니다. </li>
</ol>


<h2>제2장 회원의 가입,탈퇴 및 이용제한</h2>

<h3>제5조 회원가입 (이용계약의 성립)</h3>
<ol>
<li>①	회원은 마켓뱅서비스를 통해 인터넷사이트 공간에서 콘텐츠열람, 소통, 주류 주문등을 할 수 있습니다. 회사는 필요에 따라 회원의 구분 및 서비스 내용을 변경, 수정할 수 있습니다.</li>
<li>②	회원 가입은 이용자가 인터넷사이트에서 회사가 요청하는 정보(사업자정보, 아이디, 비밀번호, 이메일주소, 전화번호 등)를 등록하여 가입신청을 하고 회사가 위 신청을 승낙함으로써 이루어집니다. 회사는 이용자(가입신청자)에게 본인인증기관을 통한 사업자정보 사실확인, 실명확인 또는 본인인증을 요청할 있습니다. </li>
<li>③	이용자가 회원가입 신청 양식에 따라 필요한 항목들을 기입한 후 약관에 동의 한다는 의사 표시를 함으로써 이용계약이 성립합니다. </li>
<li>④	회사는 다음 각 호에 해당하는 가입신청에 대하여는 승낙을 하지 않을 수 있으며, 회원가입이 완료된 이후에도 다음 각 호에 해당하는 사유가 발견된 경우에는 승낙을 철회할 수 있습니다. 이 경우 회사는 해당 신청자의 동의가 없더라도 탈퇴(가입취소) 절차를 진행하고 제공받은 정보를 폐기합니다.
    <ol>
        <li>회사가 요청하는 정보를 기재하지 않거나 허위의 정보를 입력한 경우</li>
        <li>회사가 정하는 가입절차를 이행하지 않는 경우</li>
        <li>신청자가 법령이나 이 약관을 위반함으로 인해 회원자격을 상실한 사실이 있는 경우(단, 회사의 재가입 승낙을 얻은 경우에는 예외로 합니다)</li>
        <li>회사로부터 회원자격 정지 등의 조치를 받은 회원이 그 조치기간 중에 이용계약을 임의로 해지하고 재가입 신청을 하는 경우</li>
        <li>타인의 명의를 이용하여 가입신청을 한 경우</li>
        <li>현재 정상적으로 운영중인 사업자가 아니거나, 정부 또는 공공기관등으로 부터 제재중인 사업자</li>
        <li>주류 거래와 관련한 적법한 자격을 보유하지 않았거나 허위로 제출한 경우</li>
        <li>주류 거래에 있어 다수의 채무불이행등으로 문제가 되어 이 서비스에서도 정상적인 거래를 하지 못할 것으로 판단되는 경우</li>
        <li>부정한 목적 또는 용도로 이 서비스를 이용하고자 하는 경우</li>
        <li>기타 회사가 규정한 제반 사항을 위반하며 신청하는 경우</li>
    </ol>
</li>
<li>⑤	회사는 다음 각 호에 해당하는 경우 회원가입의 승낙을 유보할 수 있습니다. 이 경우 회사는 조속히 회원가입 절차 진행을 위한 조치를 이행하고 그 결과를 공지합니다.
    <ol>
        <li>서비스 관련 설비의 여유가 없는 경우</li>
        <li>서비스를 제공하기에는 기술상 또는 업무상 문제가 있다고 판단되는 경우</li>
        <li>기타 회사가 정책상 필요하다고 인정하는 경우</li>
    </ol>
</li>
</ol>


<h3>제6조 회원정보의 변경</h3>
<ol>
<li>①	회원은 회원가입 시에 회사에게 제공한 개인정보를 인터넷사이트를 통해 열람, 수정할 수 있습니다. </li>
<li>②	회원은 회원가입 시에 제공한 개인정보가 변경된 경우 이를 인터넷사이트를 통해 수정하여야 합니다. 위 변경사항을 수정하지 않아 발생한 불이익에 대해서는 회사가 책임을 지지 않습니다.</li>
</ol>

<h3>제7조 회원정보의 관리</h3>
<ol>
<li>①	회원정보(아이디, 비밀번호, 이메일 등)의 관리책임은 회원에게 있으며, 회원은 자신의 정보를 제3자가 이용하도록 하여서는 안 됩니다.</li>
<li>②	회사는 회원이 제공한 데이터의 보관 및 데이터베이스 권리를 가지며 제공된 데이터를 다른 형태로 디지털화하여 보관할 수 있습니다.</li>
<li>③	회사는 관련법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보 보호 및 사용에 대해서는 관련법령 및 회사가 별도로 정하는 「개인정보 취급방침」이 적용됩니다. </li>
</ol>

<h3>제8조 회원에 대한 통지</h3>
<ol>
<li>①	회사가 회원에게 통지를 하는 경우 이 약관에 달리 정함이 없는 한 회원이 등록한 이메일주소, 핸드폰, 카카오톡 등으로 통지할 수 있습니다.</li>
<li>②	회사는 회원 전체에 대해 통지를 하는 경우 인터넷사이트 초기화면 또는 게시판등에 게시함으로써 전항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항은 전항의 방법으로 통지합니다.</li>
<li>③	회원이 이메일주소, 핸드폰, 카카오톡등의 정보를 최신으로 유지하지 않아 발생하는 통지의 누락에 회사는 책임을 지지 않습니다. </li>
</ol>

<h3>제9조 회원 탈퇴 (이용계약의 종료)</h3>
<ol>
<li>①	회원이 회원에서 탈퇴하고자 하는 경우 회사가 정한 절차에 따라 회원탈퇴를 요청하고 회사는 이를 처리합니다. 탈퇴를 완료하기 위해서는 현재 진행중인 거래가 있는 경우 이를 완료하거나 철회 또는 취소하여야 하며, 이 과정에서 발생하는 불이익은 회원본인이 부담하여야 합니다. 구매자가 판매자에게 채무를 불이행 한 경우 채무를 전부 이행할 때까지 회사는 구매자 회원의 회원 탈퇴를 제한할 수 있습니다. </li>
<li>②	회원 탈퇴하는 경우 관련법 및 개인정보취급방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 즉시 회원의 정보를 파기 처리 합니다. 단, 판매자와 구매자간의 거래를 통해 탈퇴완료 이전에 발생한  주문이력, 반품이력, 결제이력 등을 포함하는 거래 이력정보는 대상에서 제외합니다.  </li>
<li>③	회사는 다음 각 호의 사유가 발생하는 경우 회원의 서비스 이용을 중단하거나 일시정지, 회원 탈퇴등의 조치를 할 수 있습니다. 
    <ol>
        <li>본 약관 5조 4항의 각호에 해당하는 경우</li>
        <li>회원이 회사 서비스의 운영을 고의 및 과실로 방해하는 경우</li>
        <li>서비스이용과 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우</li>
        <li>주문한 상품 또는 서비스에 특별한 하자가 없음에도 불구하고 상습적으로 취소, 반품등을 하는 행위</li>
        <li>회사의 서비스와 무관하거나 부적합한 상품을 등록 할 경우 </li>
        <li>판매자가 상품의 배송, 반품, 환불처리등 거래에 있어 동반되는 행위를 지연, 의도적 회피등으로 구매자로부터 지속적인 개선요구가 있고 회사가 개선에 대한 요청을 했음에도 개선되지 아니할 때 </li>
        <li>회사의 약관에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우</li>
        <li>공공질서, 미풍양속에 반하는 행위를 하는 경우</li>
        <li>기타 회사가 회원의 이용정지를 진행할 합리적인 사유가 있는 경우</li>
    </ol>
</li>
<li>④	회사가 3항의 조취를 취하는 경우 회사는 회원에게 이메일, 전화번호, 카카오톡, 기타의 방법을 통하여 이용중단 또는 일시정지 또는 회원탈퇴를 통지하며, 통지된 시점에 실행됩니다. </li>
<li>⑤	회원탈퇴(이용계약의 종료)와 관련하여 발생한 손해는 이용계약이 종료된 해당 회원이 부담하며 회사는 일체의 책임을 부담하지 않습니다. </li>
<li>⑥	회원이 탈퇴하는 경우 회원이 작성하였던 콘텐츠(게시물,이미지,상품정보, 리뷰등)는 자동 삭제되지 않으며, 회원이 본인이 작성한 콘텐츠의 삭제를 원할 경우에는 회원 탈퇴 이전에 게시물을 직접 삭제해야 합니다.</li>
</ol>

<h2>제3장 서비스의 이용</h2>

<h3>제10조 서비스 내용 및 제공 </h3>
<ol>
<li>①	회사가 회원에게 제공하는 서비스의 종류는 다음과 같습니다. 단 회사는 일부 서비스의 이용조건 및 범위를 별도로 정하여 운영할 수 있으며 해당 사항은 회원에게 공지합니다. 
    <ol>
    <li>인터넷사이트를 통해 회원 간의 상품의 거래 중개가 이뤄질 수 있도록 온라인 거래장소를 제공하는 서비스 및 부가 서비스 </li>
    <li>주문과 거래 관련 지원서비스</li>
    <li>상품정보검색서비스</li>
    <li>전자결제대행사를 통한 결제대행</li>
    <li>기타 회사가 직접 또는 다른 회사와의 제휴 계약등을 통해 회원에게 제공하는 일체의 서비스 </li>
    </ol>
</li>
<li>②	회원은 판매자의 판매관련한 정책 등을 충분히 숙지하고 서비스를 이용하여야 합니다. 회사는 통신판매중개자로서 통신판매의 당사자가 아니고 판매자가 판매, 취소, 환불등의 거래 정책을 별도로 운영하므로 구매자회원은 서비스 이용시 반드시 그 내용을 확인해야 합니다. 구매자회원이 내용을 제대로 숙지 하지 못해 발생한 피해에 대해서 회사는 책임을 부담하지 않습니다.</li>
<li>③	마켓뱅 서비스를 통한 판매자와 상품을 구매한 구매자간의 거래와 관련하여 공급 또는 주문의사의 존부 및 진정성, 등록된 상품의 품질 및 완전성, 안전성, 적법성, 타인의 권리에 대한 비침해성, 판매자 또는 구매자가 인터넷사이트를 통해 입력하는 정보, 판매자 또는 구매자가 개인간의 연락을 통해 전달하는 정보의 진실성 또는 적법성등 일체에 대하여 회사는 보증하지 아니하며 회원간의 상품 거래와 관련된 일체의 책임은 각 거래의 당사자 에게 있습니다.  </li>
</ol>

<h3>제11조 구매자의 서비스 이용</h3>
<ol>
<li>①	구매자는 상품을 주문함에 있어 판매자가 제공하는 정보 및 조건등을 정확하게 확인한 후에 주문을 진행하여야 하며, 이를 확인하지 않아 발생하는 모든 책임은 구매자 본인에게 있습니다. </li>
<li>②	구매자가 서비스를 이용함에 있어 입력하는 이용정보 및 사업자 관련정보의 오류로 인해 발생하는 책임은 구매자 본인에게 있습니다. </li>
<li>③	회사는 통신판매중개자로서 판매자가 등록한 상품 또는 거래 조건에 대해 어떠한 보증이나 대리를 하지 않습니다. 구매자는 상품정보 및 거래 조건을 정확하게 확인하여 주문을 진행하여야 하며 주문 및 거래에 대한 책임은 구매자 본인에게 있습니다. 구매자가 주문한 상품을 반품 또는 환불을 요청하는 경우 해당 요청은 판매자의 거래조건에 따라 거절될 수 있으며, 회사는 이에 책임을 지지 않습니다. </li>
<li>④	회사는 판매자 또는 제휴업체등으로부터 전달받은 정보, 회사가 직접 작성한 정보 및 콘텐츠를 인터넷사이트 내 게시하거나 휴대전화번호,이메일, 카카오톡등으로 전달할 수 있으며 이를 통해 상품을 주문하게 되는 경우에도 주문에 대한 결정은 구매자가 스스로 책임을 져야 하며 회사는 상품의 품질, 배송, 반품, 환불등의 거래에 책임을 지지 않으며 거래에 대한 모든 책임은 당사자인 판매자와 구매자에게 있습니다.  </li>
<li>⑤	구매자가 주류를 주문할 수 있는 적법한 권한을 가지고 있지 않거나 미성년자 인 경우 회사는 즉시 거래를 중지 또는 취소시킬 수 있으며 회원권한 정지 또는 탈퇴등의 조치를 할 수 있습니다. </li>
</ol>

<h3>제12조 서비스의 변경 및 종료</h3>
<ol>
<li>①	회사는 운영상, 기술상의 필요에 따라 회사가 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다. 변경되는 서비스 내용 및 제공일자가 예측되는 경우 사전에 사이트를 통하여 공지하거나 이용자에게 통지합니다. 단, 회사가 통제할 수 없거나 예측할 수 없는 사유로 인하여 사전 공지 또는 통지가 불가능한 경우에는 사후 공지로 갈음할 수 있습니다.</li>
<li>②	회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.</li>
<li>③	회사는 향후 운영상 필요에 따라 서비스의 일부 또는 전부를 유료화 할 수 있습니다. 이 경우 관련 내용, 변경될 이용방법에 대해 변경 30일전에 이용자가 알아볼 수 있도록 고지 합니다.    </li>
<li>④	회사는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 경영악화, 기술진보와 관련된 차세대 서비스로의 전환필요성, 서비스 제공과 관련된 회사의 정책변경 등 상당한 이유가 있는 경우 서비스 전부 또는 일부를 중단할 수 있습니다.</li>
<li>⑤	4항의 사유로 인한 서비스의 종료가 발생하는 경우 제8조에 정한 방법으로 이용자에게 통지하거나 이용자가 알아볼 수 있도록 공지 사항으로 게시합니다.</li>
<li>⑥	회사는 본 조항에 따른 서비스의 변경, 중지 및 종료로 발생하는 문제에 대해서 어떠한 책임도 지지 않습니다. </li>
</ol>

<h3>제13조 서비스 제공시간 및 점검 </h3>
<ol>
<li>①	서비스는 연중무휴, 1일 24시간 제공을 원칙으로 합니다. 다만 회사는 각 서비스 별로 이용가능 시간을 별도로 지정하여 운영할 수 있습니다. </li>
<li>②	회사는 정보통신설비의 보수점검, 교체 및 고장 또는 운영상 상당한 이유가 있는 경우 서비스 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제8조에 정한 방법으로 회원에게 사전에 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
<li>③	회사는 서비스 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 인터넷사이트에 공지한 바에 따릅니다. 다만 긴급을 요하는 경우에는 위 공지를 생략하고 점검을 실시할 수도 있습니다.</li>
<li>④	국가비상사태, 정전, 서비스 설비의 긴급장애, 통상적인 수준의 트래픽을 과도하게 초과하여 발생하는 장애 등(이에 국한되지 않음) 회사가 의도하지 않은 장애로 인한 서비스의 중단에 대해 회사는 어떠한 책임도 지지 않습니다. </li>
</ol>

<h3>제14조 정보의 제공 및 광고의 게재</h3>
<ol>
<li>①	회사는 서비스의 이용과 관련한 정보를 인터넷사이트의 공지, 콘텐츠, 이메일 전송 등의 방법으로 회원에게 제공할 수 있습니다. 회원은 관련법에 따른 거래 관련 정보 및 고객문의에 대한 답변 등을 제외하고는 언제든지 위 이메일 수신을 거절할 수 있습니다.</li>
<li>②	회사는 서비스 운영과 관련하여 인터넷사이트, 회원에게 발송하는 이메일 , 등에 광고를 게재할 수 있습니다. 광고가 게재된 이메일을 수신한 회원은 언제든지 위 이메일 수신을 거절할 수 있습니다.</li>
<li>③	회사는 회원이 서비스를 이용하면서 발생시키는 전자데이터정보(검색기록, 쿠키, IP주소, 브라우저유형, 운영체제, 사용일시, 로그정보 등) 를 수집하며 더욱 원할한 서비스의 제공을 위해 활용할 수 있습니다. </li>
</ol>

<h3>제15조 포인트</h3>
<ol>
<li>①	회사는 필요시 포인트제도를 운영할 수 있습니다. 포인트는 회사의 정책에 따라 회원에게 부여되며, 포인트 별 적립기준, 사용방법, 사용기한 및 제한에 관한 사항은 별도로 공지합니다. 포인트 사용기한에 대해 별도로 안내하지 않는 경우 그 사용기한은 1년으로 봅니다.</li>
<li>②	포인트는 사용기한 동안 사용하지 않거나 회원 탈퇴 또는 회원의 자격을 상실한 경우 자동으로 소멸됩니다. 이 경우 소멸된 포인트는 복구되지 않습니다.</li>
<li>③	지급된 포인트는 제3자에게 양도가 불가능합니다. 만일 회원이 회사가 승인하지 않은 방법 또는 부적절한 방법으로 포인트를 획득한 경우는 회사는 이를 즉시 회수할 수 있고, 기 사용된 포인트가 현금을 대체한 경우 사용원복을 요구할 수 있습니다.</li>
<li>④	회사의 정책 변경에 따라 포인트에 대한 정책은 변경될 수 있으며, 회원에게 불리한 변경의 경우 사전 고지 합니다. 회원의 서비스 계속 이용 시 변경된 정책에 동의한 것으로 간주합니다.</li>
<li>⑤	포인트 서비스 종료 시 적립된 포인트는 회사가 별도 지정하는 날까지 회사가 지정하는 바에 따라 사용하여야 하며, 이후 미사용 잔여분은 소멸합니다.</li>
</ol>

<h3>제16조 게시물의 저작권 및 배타적 이용허락 </h3>
<ol>
<li>①	회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다. 회원이 작성한 게시물이 타인의 저작권을 침해하는 경우 그에 대한 책임은 해당 회원 본인에게 있습니다. </li>
<li>②	회원은 회사에게 자신이 작성한 게시물을 독점적으로 이용할 수 있는 권리를 부여합니다. 회사는 회원이 작성한 게시물을 노출,판촉,홍보등의 자료로 활용할 수 있으며 필요한 범위내에서 게시물의 일부를 수정, 복제 및 편집할 수 있습니다. 단, 회원이 본인이 작성한 게시물이 판촉,홍보등의 자료로 사용되지 않길 원하는 경우 언제든지 해당 사항을 회사에 요청할 수 있습니다.</li>
<li>③	회사는 회원이 인터넷사이트에 등록한 게시물이 다음 각 호에 해당한다고 판단되는 경우에 사전 통지 없이 이를 삭제할 수 있고, 이에 대하여 회사는 어떠한 책임도 지지 않습니다. 해당 사유에 해당하는지에 관해서는 회사가 합리적, 객관적으로 판단하고, 필요한 경우 내부의 심의절차를 거쳐서 판단합니다. 회사는 해당 회원에게 삭제 사유를 통지하고, 회원은 이에 대해 이의를 제기할 수 있습니다.
    <ol>
    <li>타인을 비방하거나 타인의 명예를 훼손할 우려가 있는 내용인 경우</li>
    <li>공서양속에 위반되는 내용이거나, 선정적 또는 폭력적인 내용인 경우</li>
    <li>범죄적 행위에 결부된다고 인정되는 경우</li>
    <li>인종차별에 해당하는 내용인 경우</li>
    <li>회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
    <li>음란물에 해당하거나 음란사이트를 링크한 내용인 경우</li>
    <li>회사로부터 콘텐츠 게시권한을 부여받지 않은 자의 게시물인 경우</li>
    <li>회사로부터 사전 승인 받지 아니한 상업광고, 판촉내용을 게시하는 경우</li>
    <li>정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 경우</li>
    <li>	회사의 사업목적을 벗어나는 내용이 포함된 콘텐츠인 경우</li>
    <li>	기타 관계법령에 위반된다고 판단되는 경우</li>
    </ol>
</li>
</ol>

<h3>제17조 저작권의 귀속 및 책임</h3>
<ol>
<li>①	회사가 서비스를 통해 제공하는 동영상, 사진, 링크등 모든 정보와 콘텐츠 (“저작물 등”)의 저작권 기타 지식재산권은 원칙적으로 해당 저작물 등의 저작권자에게 귀속됩니다. </li>
<li>②	회사가 작성한 저작물에 대한 저작권 및 기타 지적 재산권은 회사에 귀속됩니다. </li>
<li>③	이용자는 다음 각 호를 준수해야 합니다. 이용자가 다음 각 호를 준수하지 않아 발생하는 문제에 대한 책임은 모두 해당 이용자에게 있습니다.
    <ol>
        <li>이용자는 회사의 사전 허가 없이는 저작물 등을 직·간접적으로 변조, 복사, 배포, 출판, 전시, 판매할 수 없습니다. 또한 저작물 등을 이용해 상품을 제작하거나 타 서비스에 인용할 수 없습니다. 사용처 및 목적을 불문합니다. </li>
        <li>이용자는 저작물 등을 왜곡, 개작, 변조하지 않을 것을 확약하며 만일 이로 인해 문제가 발생하는 경우 회사는 그에 관해 책임을 지지 않습니다. </li>
        <li>회사의 서비스를 통한 저작물 등의 이용은 서비스 별로 회사의 사전 안내에 따라 그 횟수와 기간이 제한될 수 있으며, 전자매체로의 복사, 인쇄, 재사용을 위한 저장은 허가되지 않습니다. </li>
        <li>이 약관 또는 관련 법령에 의해 저작물 등의 인용 등이 예외적으로 허용되는 경우에도 해당 저작물 등에는 반드시 출처(회사)를 명시해야 합니다.</li>
        <li>이용자가 회사의 인터넷사이트에 게시물을 게시하는 경우 타인의 지식재산권을 침해하는 일이 발생하지 않도록 하여야 하며 이에 대한 모든 책임은 게시물을 작성한 본인이 부담 합니다.</li>
    </ol>
</li>
<li>④	회사는 이용자가 인터넷사이트에 게시한 게시물에 포함된 정보에 대하여 어떠한 보증이나 책임도 부담하지 않습니다.</li>
</ol>



<h2>제4장 당사자의 의무</h2>

<h3>제18조 회사의 의무</h3>
<ol>
<li>①	회사는 계속적이고 안정적으로 서비스를 제공하기 위해 노력합니다.</li>
<li>②	회사는 서비스 이용에 관해 회원이 제기한 의견이나 불만이 정당하다고 인정할 경우에는 이를 적절히 처리합니다. 회원이 제기한 의견이나 불만 사항에 대해서는 전자우편이나 전화,메시지등을 통하여 회원에게 처리과정 및 결과를 전달합니다.</li>
<li>③	회사는 회원의 개인정보를 안전하게 보호하고 회원 본인의 승낙 없이 제3자에게 누설, 배포하지 않아야 합니다. </li>
</ol>

<h3>제19조 이용자의 의무</h3>
<ol>
<li>①	이용자는 본 약관, 이용안내 및 서비스상에 공지된 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.</li>
<li>②	이용자는 서비스 이용과 관련하여 다음의 행위를 하여서는 안됩니다.
    <ol>
    <li>서비스신청 또는 변경 시 허위내용의 등록</li>
    <li>타인 정보의 도용</li>
    <li>회사가 게시한 정보의 변경</li>
    <li>회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
    <li>회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
    <li>회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
    <li>외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 회사의 사이트에 공개 또는 게시하는 행위</li>
    <li>서비스에 게시된 게시물 또는 콘텐츠를 무단으로 복제, 재가공 및  배포하는 행위</li>
    <li>다른 회원의 개인정보를 동의 없이 수집,저장,공개하는 행위</li>
    <li>회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</li>
    <li>서비스 목적을 벗어나는 내용을 포함한 콘텐츠를 게시하는 행위</li>
    <li>서비스와 관련된 설비의 오작동이나 정보의 파괴 및 혼란을 유발시키는 바이러스, 기타 컴퓨터 코드, 프로그램등을 등록 또는 유포하는 행위</li>
    <li>이 약관 또는 관계법령에 위반되거나 불법적이거나 부당한 행위</li>
    </ol>
</li>
</ol>

<h3>제20조 양도금지</h3>

<p>회원은 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도 내지 증여하거나 질권의 목적으로 사용할 수 없습니다. </p>


<h2>제5장 정보의 보호</h2>

<h3>제21조 개인정보보호</h3>
<ol>
<li>①	회사는 회원의 개인정보보호를 최대한 보호하기 위하여 노력 합니다.</li>
<li>②	회원이 회사에 제공한 개인정보는 서비스 이용의 혼선방지, 불법적 이용에 대한 수사기관 협조, 기타 안정적인 서비스 제공을 위해 회원탈퇴 후에도 일부 보유하게 됩니다.</li>
<li>③	회사가 서비스를 중단하거나 회원이 개인정보 제공동의를 철회하는 경우 신속하게 회원의 개인정보를 파기합니다. 단, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법률에서 정하는 바에 따라 일정 정보는 보관할 수 있습니다. </li>
<li>④	아이디, 비밀번호등 계정정보의 관리에 대한 책임은 회원 당사자에게 있으며 회원의 귀책사유로 노출된 개인정보에 대해서 회사는 책임을 지지 않습니다. </li>
<li>⑤	거래의 당사자가 되는 판매자 및 구매자에게는 원활한 거래의 진행을 위해 일부 개인정보가 공유될 수 있습니다. (성명,연락처등) </li>
<li>⑥	회사가 이용자의 정보를 서비스 제공 목적을 위해 제3자에게 제공하는 경우는 다음과 같습니다. 
    <ol>
        <li>법령이 회원이 개인정보의 이용과 제3자에 대한 정보제공을 허용하는 경우</li>
        <li>배송대행업체 등에게 거래 이행에 필요한 최소한의 회원정보  (이름,주소,연락처등)가 제공되는 경우 </li>
        <li>이벤트에 참여한 회원의 최소한의 회원정보가 해당 이벤트의 주최자에게 제공되는 경우</li>
        <li>기타 회사의 약관 및 정책에 따라 회원의 동의를 구한 경우</li>
    </ol>
</li>
</ol>


<h2>제6장 기타</h2>

<h3>제22조 면책사항</h3>
<ol>
<li>①	회사는 무료로 제공하는 콘텐츠 및 서비스에 관하여 개인정보취급방침 또는 관계법령의 벌칙, 과태료 규정 등 강행규정에 위배되지 않는 한 원칙적으로 손해를 배상할 책임이 없습니다.</li>
<li>②	회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
<li>③	회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</li>
<li>④	회사는 서비스 제공과 관련한 주의의무만을 부담할 뿐 그 이외에 회원 상호간 분쟁에서 발생하는 모든 문제를 예방해야 하는 일체의 주의의무를 부담하지 않습니다. 회원 상호간 분쟁은 당사자간에 직접 해결해야 합니다.</li>
<li>⑤	회사는 회원이 서비스 내 제공한 콘텐츠 및 정보에 대한 어떠한 책임도 부담하지 않습니다. </li>
<li>⑥	회사는 통신판매중개자로써 통신판매 또는 판매의 당사자가 아니며, 서비스를 통한 모든 거래에 대한 책임은 판매자와 구매자 각 당사자에게 있으며 회사는 어떠한 책임도 부담하지 않습니다.</li>
<li>⑦	이용자는 서비스를 통해 이용하는 콘텐츠 및 정보를 스스로의 결정에 의해 선별적으로 받아들여야 하며, 이용자가 위 선별에 주의를 기울이지 않아 초래될 수 있는 손실과 손해는 원칙적으로 이용자 자신이 부담하며 회사는 이에 대해 어떠한 책임도 지지 않습니다. 다만 이용자가 콘텐츠의 내용에 관해 부적법함 또는 부적절함을 발견하여 이를 회사에 고지하는 경우 회사는 이를 검토하여 최선의 조치를 취하기로 합니다. </li>
<li>⑧	회사는 서비스에 인용된 다른 웹사이트를 통해 이용자가 취득하는 정보 등에 타인의 저작권을 침해하거나 부적절, 부정확, 기타 사회질서에 반하는 정보가 게재되어 있다 하더라도 이와 관련한 어떠한 책임도 지지 않습니다.</li>
<li>⑨	회사는 회사 및 회사의 임직원 그리고 회사 대리인의 고의 또는 중대한 과실이 없는 한 다음의 사항으로부터 발생하는 손해에 대해 책임을 지지 아니 합니다. 
    <ol>
    <li>회원정보의 허위 또는 부정확성에 기인하는 손해</li>
    <li>서비스에 대한 접속 및 서비스의 이용과정에서 발생하는 개인적인 손해 </li>
    <li>서버, 도메인등 기술적인 장애로 인해 발생하는 손해</li>
    <li>제3자로부터의 서비스와 관련한 불법적인 복제,배포,전송 또는 의도적인 방해, 바이러스 및 기타 악성프로그램의 배포등으로 인한 손해</li>
    <li>전송된 데이터의 오류,누락,파괴등으로 발생하는 손해 </li>
    </ol>
</li>
<li>⑩	본 조의 책임제한에 관한 사항은 서비스를 통하여 제공되는 정보, 광고 등 모든 내용에 대하여 적용되며 서비스가 종료되었을 경우에도 적용되며, 서비스와 관련 있는 범위에서 회사와 이용자간, 이용자간, 이용자와 제3자 간 및 제3자간에 분쟁이 발생하였을 때에 적용됩니다.</li>
</ol>

<h3>제23조 분쟁조정</h3>
<ol>
<li>①	회사는 회원간의 분쟁이 발생하는 경우 제3자의 입장에서 공정하게 이를 조정하기 위한 노력을 합니다. </li>
<li>②	회사는 회원으로부터의 소정의 절차에 의해 제기되는 불만이나 의견이 정당하다고 인정할 경우 적절한 절차를 거쳐 처리합니다. </li>
</ol>
<h3>제24조 개별약관과의 관계</h3>
<ol>
<li>①	회사가 별도로 작성하는 개별약관 및 규정은 이 약관과 일체를 구성하며 상호 보완하는 의미를 가집니다.</li>
<li>②	전항의 규정에서 정하지 않은 내용에 관해서는 이 약관이 적용됩니다.</li>
</ol>

<h3>제25조 준거법 및 재판관할</h3>
<ol>
<li>①	이 약관의 해석에 관해서는 대한민국 법률을 준거법으로 합니다.</li>
<li>②	회사와 회원간 발생한 분쟁에 관한 소송에 관해서는 서울중앙지방법원을 제1심 전속관할로 합니다. </li>
</ol>

시행일자 : 2024년 7월 30일


</article>
</template>
<style scoped lang="sass">
article
    padding: 16px
    height: 80vh
    overflow-y: scroll
    h1
        font-size: 16px
        font-weight: bold
        padding: 
            bottom: 20px
    h2
        font-size: 14px
        padding: 
            top: 18px
        color: #911054
    h3
        font-size: 14px
        padding: 
            top: 12px
    h2, h3
        font-weight: bold
        padding: 
            bottom: 12px
    p
        font-size: 14px
        line-height: 22px
        padding: 
            bottom: 10px
        span
            font-weight: bold
    [data-date_row]
        font-size: 12px
        padding: 
            top: 30px
    & > ol
        list-style: none
        li
            font-size: 14px
            line-height: 22px
            padding:
                left: 12px
                bottom: 10px
            text-indent: -17px
            &:nth-child(n+10)
                padding-left: 12px
                text-indent: -20px
            span
                font-weight: bold
            ol
                padding-top: 10px
                li
                    padding:
                        left: 12px
                        bottom: 5px
                    text-indent: -16px
            [data-table_box]
                width: 100%
                margin-top: 15px
                border-collapse: collapse
                box-sizing: border-box
                border: 1px solid #222
                tr
                    border: 1px solid #222
                    th
                        width: 50%
                        height: 30px
                        text-align: center
                        line-height: 30px
                        font-weight: bold
                        background-color: #eeeeee
                        border: 1px solid #222
                    td
                        padding: 1rem
                        border: 1px solid #222
                        display: table-cell
                        vertical-align: middle
            [data-table_box="6"]
                th
                    &:first-of-type
                        width: 35%
                    &:nth-of-type(2)
                        width: 45%
                td
                    border: 0.1rem solid #222
                    &:last-of-type
                        text-align: center
</style>