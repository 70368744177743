import router from '@/router'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    user_info: {},
    sidebarVisible: '',
    sidebarUnfoldable: false,
    searchData: {
      order: {},
      return: {},
      deal: {},
      clclt: {},
      product: {},
      stock: {},
      store: {},
      taxCreate: {},
      taxSend: {},
    },
    chatData: {
      buyerInfo: {},
      userNo: 0,
      companyNo: 0,
      isNotice: false,
      isOrder: false,
    },
    isPopup: false,
    popupData: '',
  },
  mutations: {
    setPopup(state, value) {
      state.isPopup = value
    },
    setPopupData(state, value) {
      state.popupData = value
    },
    setChatData(state, payload) {
      state.chatData = payload
      console.log(state.chatData)
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    userIdToken(state, payload) {
      state.userId = payload
      router.go()
    },
    AuthCode(state, payload) {
      state.authCode = payload
      router.go()
    },
    registerOk(state) {
      delete state.authCode
    },
    checkEmailOk(state) {
      delete state.userId
    },
    searchDataOrder(state, payload) {
      state.searchData.order = payload
    },
    searchDataOrderClear(state) {
      state.searchData.order = {
        correspondentNo: '',
        defaultOrderStatus: ['O01', 'O22', 'O02'],
        managerNo: '',
        orderStatus: '',
        page: 1,
        searchEndDate: null,
        searchStDate: null,
        sort: '',
      }
    },
    searchDataReturn(state, payload) {
      state.searchData.return = payload
    },
    searchDataReturnClear(state) {
      state.searchData.return = {
        correspondentNo: '',
        managerNo: '',
        orderStatus: '',
        page: 1,
        searchEndDate: null,
        searchStDate: null,
        sort: '',
      }
    },
    searchDataDeal(state, payload) {
      state.searchData.deal = payload
    },
    searchDataDealClear(state) {
      state.searchData.deal = {
        correspondentNo: '',
        defaultOrderStatus: ['O11', 'O21'],
        managerNo: '',
        orderStatus: '',
        page: 1,
        searchEndDate: null,
        searchStDate: null,
        sort: '',
      }
    },
    searchDataClclt(state, payload) {
      state.searchData.clclt = payload
    },
    searchDataProduct(state, payload) {
      state.searchData.product = payload
    },
    searchDataProductClear(state) {
      state.searchData.product = {
        page: 1,
        sort: '',
        searchValue: '',
      }
    },
    searchDataStock(state, payload) {
      state.searchData.stock = payload
    },
    searchDataStockClear(state) {
      state.searchData.stock = {
        page: 1,
        sort: '',
        searchValue: '',
      }
    },
    searchDataStore(state, payload) {
      state.searchData.store = payload
    },
    searchDataStoreClear(state) {
      state.searchData.store = {
        page: 1,
        sort: '',
        storeName: '',
      }
    },
    searchDataTaxCreate(state, payload) {
      state.searchData.taxCreate = payload
    },
    searchDataTaxCreateClear(state) {
      state.searchData.taxCreate = {
        page: 1,
        sort: '',
        correspondentNo: '',
        endDate: null,
        managerNo: '',
        startDate: null,
        storeTypeCode: '',
      }
    },
    searchDataTaxSend(state, payload) {
      state.searchData.taxSend = payload
    },
    searchDataTaxSendClear(state) {
      state.searchData.taxSend = {
        page: 1,
        sort: '',
        correspondentNo: '',
        endDate: null,
        managerNo: '',
        startDate: null,
        storeTypeCode: '',
        orderTaxBillStatusCode: '',
      }
    },
    clearSerach(state) {
      const data = {
        order: {},
        return: {},
        deal: {},
        clclt: {},
        product: {},
        stock: {},
        store: {},
      }
      state.searchData = data
    },
    userInfoInsert(state, payload) {
      state.user_info = payload
    },
  },
  getters: {
    getUserInfo() {},
  },
  modules: {},
  plugins: [createPersistedState()],
})
