<template>
<CFooter>
	<dl>
		<dt>쓰리랩스(주)</dt>
		<dd>사업자등록번호: 361-87-00890 | 문의사항: 02-3273-2869 | E-mail:
			<a href="mailto:contact@marketbang.kr">contact@marketbang.kr</a>
		</dd>
	</dl>
	<CButtonGroup>
		<CButton color="light" size="sm" type="button" @click="()=>agreement1_popup=true">서비스 이용약관</CButton>
		<CButton color="light" size="sm" type="button" @click="()=>agreement2_popup=true">판매자 개별약관</CButton>
		<CButton color="light" size="sm" type="button" @click="()=>agreement3_popup=true">개인정보 취급방침</CButton>
	</CButtonGroup>
</CFooter>
<CModal :visible="agreement1_popup" @close="()=>agreement1_popup=false">
	<CModalHeader><CModalTitle>서비스 이용약관</CModalTitle></CModalHeader>
	<CModalBody><UseTerms /></CModalBody>
</CModal>
<CModal :visible="agreement2_popup" @close="()=>agreement2_popup=false">
	<CModalHeader><CModalTitle>판매자 개별약관</CModalTitle></CModalHeader>
	<CModalBody><SellerTerms /></CModalBody>
</CModal>
<CModal :visible="agreement3_popup" @close="()=>agreement3_popup=false">
	<CModalHeader><CModalTitle>개인정보 취급방침</CModalTitle></CModalHeader>
	<CModalBody><PrivacyPolicy /></CModalBody>
</CModal>
</template>
<script setup>
import{ ref }from"vue";
import UseTerms from "@/views/terms/UseTerms.vue";
import PrivacyPolicy from "@/views/terms/PrivacyPolicy.vue";
import SellerTerms from "@/views/terms/SellerTerms.vue";
const agreement1_popup = ref( false );
const agreement2_popup = ref( false );
const agreement3_popup = ref( false );
</script>
<style scoped lang="sass">
.footer
	flex-direction: column
	align-items: flex-start
	padding: 10px 20px
.btn-group
	button
		font-size: 12px
dl
	display: flex
	flex-direction: column
	gap: 10px
	margin: 0
	margin-left: 8px
dt, dd
	line-height: 14px
dt
	font-size: 14px
dd
	display: flex
	align-items: center
	margin: 0
	font-size: 12px
	line-height: 14px
	a
		color: var(--cui-footer-color)
</style>
