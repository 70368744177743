export default {
	dateFormatYMD(date) {
		if (this.nullCheck(date)) return "";
		date = new Date(date)
		const year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		if (month < 10) {
			month = `0${month}`
		}

		if (day < 10) {
			day = `0${day}`
		}

		return `${year}-${month}-${day}`
	},
	dateFormatTMR(date) {
		date = new Date(date)
		date.setDate(date.getDate() + 1)
		const year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()

		if (month < 10) {
			month = `0${month}`
		}

		if (day < 10) {
			day = `0${day}`
		}

		return `${year}-${month}-${day}`
	},
	dateFormatYDA(date) {
		date = new Date(date)
		date.setDate(date.getDate() - 1)
		const year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate() 

		if (month < 10) {
			month = `0${month}`
		}

		if (day < 10) {
			day = `0${day}`
		}

		return `${year}-${month}-${day}`
	},
	dateFormatYMDT( date ){
		if( this.nullCheck(date) ) return '';
		date = new Date(date);
		const year = date.getFullYear();
		let month = date.getMonth() + 1;
		let day = date.getDate();
		let hour = date.getHours();
		let minute = date.getMinutes();
		if(month < 10) month = `0${month}`;
		if(day < 10) day = `0${day}`;
		if(hour < 10) hour = `0${hour}`;
		if(minute < 10) minute = `0${minute}`;
		return `${year}-${month}-${day}T${hour}:${minute}`;
	},
	dateFormatDT( date ){
		if( this.nullCheck(date) ) return "";
		date = new Date(date);
		let hour = date.getHours();
		let minute = date.getMinutes();
		if(hour < 10) hour = `0${hour}`;
		if(minute < 10) minute = `0${minute}`;
		return `${hour}시 ${minute}분`;
	},
	dateFormatAMPM( date ){
		if (this.nullCheck(date)) return "";
		date = new Date(date);
		let hours = date.getHours();
		let minutes = date.getMinutes();
		// Check whether AM or PM
		let newformat = hours >= 12 ? 'PM' : 'AM';
		// Find current hour in AM-PM Format
		hours = hours % 12;
		// To display "0" as "12"
		hours = hours ? hours : 12;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		return hours + ':' + minutes + ' ' + newformat;
	},
	nullCheck( value ){
		return (value == '' || value == null || value == undefined || value == 'undefined');
	},
	phoneDashInsert( phoneNum ){
		if (!phoneNum) return "";
		var newPhone = "";
		var re1 = /^1(5|6|8)[0-9]+$/;
		var re2 = /^(02.{0}|01.{1}|[0-9]{3})[0-9]{1,2}$/;
		var re3 = /^(02.{0}|01.{1}|[0-9]{3})[0-9]{3,6}$/;
		var re3_1 = /^02.{1}[0-9]{3,6}$/;
		var ok = re1.exec(phoneNum);
		if( ok ){
			newPhone = phoneNum.replace(/(^1[0-9]{3})([0-9]+)/, "$1-$2");
		}else{
			ok = re2.exec(phoneNum);
			if (ok) {
				newPhone = phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)/, "$1-$2");
			} else {
				ok = re3.exec(phoneNum);
				if (ok) {
					ok = re3_1.exec(phoneNum);
					if (ok || phoneNum.length > 6) {
						newPhone = phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]+)/, "$1-$2-$3");
					} else {
						newPhone = phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)/, "$1-$2");
					}
				} else {
					if (phoneNum.startsWith("02") && phoneNum.length < 4) {
						newPhone = phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)/, "$1-$2");
					} else {
						newPhone = phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
					}
				}
			}
		}
		return newPhone ? newPhone : "";
	},
	addComma( num ){
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	},
	inputNumberOnly( value ){
		return value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
	},
	iconedName(code, value) {
		const data = {
			"N1001": "레몬🍋",
			"N1002": "라임🍋",
			"N1003": "자몽🍊",
			"N1004": "구즈베리🍇",
			"N1005": "배🍐",
			"N1006": "사과🍎",
			"N1007": "청사과🍏",
			"N1008": "복숭아🍑",
			"N1009": "멜론🍈",
			"N1010": "구아바🍐",
			"N1011": "파인애플🍍",
			"N1012": "패션푸룻🍇",
			"N1013": "리치🍑",
			"N1014": "말린 살구🍑",
			"N1015": "오렌지 펄🍊",
			"N1016": "바나나🍌",
			"N1017": "모과🍐",
			"N1018": "오렌지🍊",
			"N1019": "자몽필🍊",
			"N1020": "라임필🍋",
			"N1021": "레몬필🍋",
			"N1022": "살구🍑",
			"N1023": "참외",
			"N1024": "키위🥝",
			"N1025": "감🍊",
			"N2001": "라즈베리🍓",
			"N2002": "블랙커런트🍇",
			"N2003": "블랙베리🍇",
			"N2004": "딸기🍓",
			"N2005": "체리🍒",
			"N2006": "자두🍑",
			"N2007": "건자두🍑",
			"N2008": "블루베리🫐",
			"N2009": "레드커런트🍓",
			"N2010": "크렌베리🍓",
			"N2011": "사워체리🍒",
			"N2012": "산딸기🍓",
			"N2013": "석류🍒",
			"N2014": "무화과",
			"N2015": "앵두🍒",
			"N3001": "허니써클💐",
			"N3002": "오렌지 꽃🌼",
			"N3003": "산사나무🌳",
			"N3004": "린덴🌼",
			"N3005": "자스민💐",
			"N3006": "아카시아🌿",
			"N3007": "장미🌹",
			"N3008": "라벤더💐",
			"N3009": "제비꽃💐",
			"N3010": "엘더플라워🌸",
			"N3011": "동백꽃🌺",
			"N3012": "목련",
			"N3013": "국화🌼",
			"N3014": "안개꽃",
			"N4001": "고추(피망)🌶️",
			"N4002": "펜넬(회향)🌿",
			"N4003": "토마토🍅",
			"N4004": "풀🌱",
			"N4005": "딜🌿",
			"N4006": "타임🌿",
			"N4007": "민트🍃",
			"N4008": "양치식물🌿",
			"N4009": "건초🍂",
			"N4010": "담배(잎)🍃",
			"N4011": "월계수 잎🍂",
			"N4012": "유칼립투스🍃",
			"N4013": "파프리카🫑",
			"N4014": "양배추🥬",
			"N4015": "올리브🫒",
			"N4016": "잔디🌱",
			"N4017": "로즈마리🌿",
			"N4018": "세이지🌿",
			"N4019": "쑥🌿",
			"N5001": "소나무🌲",
			"N5002": "삼나무(향나무)🌳",
			"N5003": "백단향(sandalwood)🌳",
			"N5004": "오크🌫️",
			"N5005": "아몬드🌰",
			"N5006": "헤이즐넛🌰",
			"N5007": "코코넛🥥",
			"N5008": "정향🧂",
			"N5009": "육두구🌰",
			"N5010": "감초🧂",
			"N5011": "시나몬🧂",
			"N5012": "후추🧂",
			"N5013": "바닐라🌫️",
			"N5014": "타르🧂",
			"N5015": "스모크🌫️",
			"N5016": "커피☕",
			"N5017": "토스트🌫️",
			"N5018": "초콜렛🍫",
			"N5019": "캬라멜🍯",
			"N5020": "가죽👝",
			"N5021": "코르크🪵",
			"N5022": "백후추🧂",
			"N5023": "다크초콜렛🍫",
			"N5024": "생강",
			"N5025": "코코넛🥥",
			"N5026": "훈제🌫️",
			"N5027": "호두🥜",
			"N5028": "버터",
			"N5029": "우유🥛",
			"N5030": "크림🌫️",
			"N5031": "코코아☕️",
			"N6001": "기름🥃",
			"N6002": "부싯돌🧱",
			"N6003": "요오드🧪",
			"N6004": "흙🪴",
			"N6005": "철🪨",
			"N6006": "석유⛽️",
			"N6007": "고무",
			"N6008": "돌🪨",
			"N6009": "젖은 잎🍂",
			"N6010": "낙엽🍂",
			"N9001": "이스트🧂",
			"N9002": "버터🧈",
			"N9003": "꿀🍯",
			"N9004": "버섯🍄",
			"N9005": "트러플(송로버섯)🍄",
			"N9006": "리무버🧪",
			"N9007": "식초🍇",
			"N9008": "양파🧅",
			"N9009": "스위트 콘🌽",
			"N9010": "치즈🧀",
		};

		if (data[code]) {
			return data[code];
		}

		return value;
	},
}
