<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <span class="managerName">{{
        user_info.managerRole === "ROLE_SELLER_MANAGER" ?
          user_info.managerName : "Master"
      }}님</span>
      <CButton color="danger" variant="outline" size="sm" @click="logout">로그아웃</CButton>
    </CContainer>
  </CHeader>

  <!-- 팝업모달 -->
  <CModal
    alignment="center"
    :visible="this.popup_visible"
    @close="
      () => {
        PopupClose()
      }
    "
  >
    <CModalHeader>
      <CModalTitle></CModalTitle>
    </CModalHeader>
    <CModalBody>
      <a :href="this.data.popupLink" target="_blank" @click="PopupClick()">
        <img :src="this.data.popupFile.fileLink" :alt="this.data.popupFile.fileName"/>
      </a>
      <div class="mt-3">
        <label for="show_yn">
          <input type="checkbox" id="show_yn" v-model="this.show_yn">
          이 팝업 다시 보지 않기
        </label>
      </div>
    </CModalBody>
  </CModal>
</template>
<style lang="sass">
.managerName
  margin-left: auto
  margin-right: 20px
</style>
<script>
import User from "@/api/user/user";
import { computed } from "vue";
import { useStore } from "vuex";
import { logo } from "@/assets/brand/logo";
import Popup from '@/api/popup/popup'

export default {
  "name" : "AppHeader",
  setup(){
    const store = useStore();
    console.log(store.state.user_info)
    return{
      logo,
      "user_info" : computed(()=>store.state.user_info),
    };
  },
  data: () => ({
    show_yn: false,
    data: {},
    popup_visible: false
  }),
  mounted() { 
    this.getPopupData();
  },
  "methods" : {
    logout(){
      User.logout().then(()=>{
        alert("로그아웃 되었습니다.");
        this.$store.commit("clearSerach");
        this.$router.push("/login");
      });
    },
    getPopupData() {
      if (this.$store.state.isPopup) {
        let popup_no_see = window.localStorage.getItem("popup_no_see")
        if (!(popup_no_see && window.localStorage.getItem("popup_no_see") == this.$store.state.popupData.bannerSellerPopupNo)) {
          this.popup_visible = true;
          this.data = this.$store.state.popupData
          Popup.PopupPV(this.data.bannerSellerPopupNo)
        }
      }
    },
    PopupClose() {
      if (this.show_yn) {
        let popupNoSee = window.localStorage.getItem("popup_no_see")

        if (popupNoSee) {
          popupNoSee = this.data.bannerSellerPopupNo;
          window.localStorage.setItem("popup_no_see", popupNoSee)
        } else {
          window.localStorage.setItem("popup_no_see", this.data.bannerSellerPopupNo)
        }
        Popup.PopupNoSee(this.data.bannerSellerPopupNo)
      } else {
        Popup.PopupClose(this.data.bannerSellerPopupNo)
      }
      this.$store.commit('setPopup', false);
    },
    PopupClick() {
      Popup.PopupClick(this.data.bannerSellerPopupNo)
    }
  }
}
</script>
