export default [
	{
		component: 'CNavItem',
		name: '주문 관리',
		to: '/order/list',
	},
	{
		component: 'CNavGroup',
		name: '거래 관리',
		to: '/deal',
		items: [
			{
				component: 'CNavItem',
				name: '거래 내역조회',
				to: '/deal/list',
			},
			{
				component: 'CNavItem',
				name: '반품 내역',
				to: '/order/return',
			},
			/* {
				component: 'CNavItem',
				name: '정산 관리',
				to: '/deal/clclt',
			}, */
		],
	},
	{
		component: 'CNavItem',
		name: '상품 관리',
		to: '/product/list',
		icon: 'null',
	},
	{
		component: 'CNavItem',
		name: '재고 관리',
		to: '/stock/list',
		icon: 'null',
	},
	{
		component: 'CNavGroup',
		name: '거래처 관리',
		to: '/store/list',
		items: [
			{
				component: 'CNavItem',
				name: '거래처 관리',
				to: '/store/list',
            },
            {
				component: 'CNavItem',
				name: '정산 관리',
				to: '/store/adjustment',
			},
            {
				component: 'CNavItem',
				name: '월별 매출 원장',
				to: '/store/month_sales_ledger',
			},
		],
	},
	{
		component: 'CNavItem',
		name: '채팅 관리',
		to: '/chat/list',
		icon: 'null',
	},
	{
		component: 'CNavGroup',
		name: '세금계산서 발행',
		to: '/tax',
		items: [
			{
				component: 'CNavItem',
				name: '세금계산서 등록',
				to: '/tax/create',
			},
			{
				component: 'CNavItem',
				name: '세금계산서 전송',
				to: '/tax/send',
			},
		],
	},
	{
		component: 'CNavGroup',
		name: '계정 관리',
		to: '/account',
		items: [
			{
				component: 'CNavItem',
				name: '공급사 채널/관리',
				to: '/account/channel',
			},
			{
				component: 'CNavItem',
				name: '담당자 등록/관리',
				to: '/account/manager',
			},
			{
				component: 'CNavItem',
				name: '계정 정보 변경',
				to: '/account/info',
			},
		],
	},
]
