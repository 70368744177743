<template>
<AppSidebar />
<div class="wrapper d-flex flex-column min-vh-100 bg-light">
	<AppHeader />
	<div class="body flex-grow-1 pb-5">
		<CContainer lg><router-view /></CContainer>
	</div>
	<AppFooter />
</div>
</template>
<script setup>
import{ useStore }from"vuex";
import{ CContainer }from"@coreui/vue";
import AppHeader from"@/components/AppHeader.vue";
import AppFooter from"@/components/AppFooter.vue";
import AppSidebar from"@/components/AppSidebar.vue";
import Category from "@/api/common/category";
const store = useStore();
Category.getProducer().then(( response )=>{
	store.commit("userInfoInsert", response.data.data);
});
</script>