<template>
<article>
<h3>제1조 총칙</h3>

<p>쓰리랩스 주식회사(“회사”)는 마켓뱅(Marketbang) 서비스(“서비스”) 이용자의 개인정보를 보호하기 위하여 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」(“정보통신망법”)을 비롯한 개인정보보호 관련 법령을 모두 준수하고 있으며 개인정보취급방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다.</p>

<h3>제2조 개인정보의 수집</h3>

<p>이용자가 서비스를 이용하기 위해 회원에 가입하는 경우, 회사는 서비스 이용을 위해 필요한 개인정보를 다음과 같이 수집합니다.</p>

<ol>
<li>1.	가입 시 수집하는 개인정보</li>
-사업자등록번호,상호,소재지,이름,전화번호,휴대폰번호,이메일

<li>2.	서비스 이용 과정에서 수집하는 정보
-서비스 이용과정에서 이용자가 입력한 정보 및 작성한 저작물</li>

<li>3.	서비스 이용 과정에서 자동 생성되는 정보
-IP Address, 쿠키, 방문일시, 서비스 이용기록, 불량 이용기록, 기기정보</li>
</ol>


<h3>제3조 개인정보 수집방법</h3>

<p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
<ol>
<li>①	회원가입 및 서비스 이용 과정에서 이용자가 직접 입력하는 경우, 해당 개인정보를 수집합니다.</li>
<li>②	고객센터 상담 과정에서 인터넷사이트, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.</li>
<li>③	오프라인 서비스에서 서면 동의를 통해 개인정보가 수집될 수 있습니다.</li>
<li>④	기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.</li>
<li>⑤	기타 해당 회원에게 동의 내용을 알리고 동의 의사표시를 확인하는 방법으로 수집될 수 있습니다.</li>
</ol>

<h3>제4조 개인정보의 이용</h3>

<p>회사는 제3조에서 정하는 바에 따라 수집한 이용자의 개인정보를 다음 각 호의 목적으로만 이용합니다.</p>
<ol>
    <li>① 회원관리<br>－ 이용자의 식별, 가입의사 및 자격요건 확인, 불량회원의 부정이용 방지, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달</li>
    <li>② 서비스의 원활한 제공 및 운영<br>－ 서비스이용계약 이행 및 서비스 제공에 따른 요금정산, 콘텐츠 제공, 구매 및 요금 결제, 상품 및 서비스의 배송, 금융거래 본인 인증 및 금융 서비스, 요금 추심</li>
    <li>③ 서비스개선, 서비스 이용기록 통계 및 분석, 홍보<br>－ 고객별 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공, 서비스의 개선, 신규서비스개발, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 서비스 이용에 대한 통계</li>
</ol>

<h3>제5조 개인정보 제공 및 위탁</h3>

<ol>
    <li>① 회사는 수집한 개인정보를 본 취급방침에 명시한 목적범위 내에서 사용하며 이용자의 사전 동의 없이 목적범위를 초과하여 이용하지 않습니다. 회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 다만 다음 각 호의 경우에는 개인정보를 이용 및 제공할 수 있습니다.
        <ol>
            <li> 이용자들이 사전에 동의한 경우</li>
            <li> 서비스 제공에 따른 요금 정산을 위하여 필요한 경우</li>
            <li> 법령의 규정에 의거하거나 수사기관의 요구가 있는 경우</li>
        </ol>
        <table data-table_box="6">
            <tr>
                <th>제공받는 자</th>
                <th>목적</th>
                <th>수집항목</th>
            </tr>
            <tr>
                <td>마켓뱅 판매자 또는 판매자회원</td>
                <td>주문확인 및 처리, 배송, 고객상담, 환불 및 반품, 기타 거래과정에서 필요한 사항</td>
                <td>사업자번호,주소지,이메일,휴대폰번호,결제정보</td>
            </tr>
            <tr>
                <td>카드사(삼성,현대,롯데,하나,신한,비씨,KB국민,NH농협,VISA,MASTER,JCB,AMEX등)</td>
                <td>본인명의 결제수단 인증, 결제처리,부정이용방지</td>
                <td>전자결제처리</td>
            </tr>
        </table>
    </li>
    <li>② 회사는 서비스 제공을 위한 업무 중 시스템 개발 및 운영, 결제처리, 휴대폰 메시지 발송 업무를 외부업체에 위탁할 수 있으며, 이 경우 수탁업체, 위탁업무내용, 위탁기간 등에 대해 고지 합니다.
        <table data-table_box>
            <tr>
                <th>수탁업체</th>
                <th>위탁업무 내용</th>
            </tr>
            <tr>
                <td>㈜링크허브</td>
                <td>알림톡 및 SMS발송, 세금계산서 전송</td>
            </tr>
            <tr>
                <td>나이스페이먼츠㈜</td>
                <td>전자결제처리</td>
            </tr>
        </table>
    </li>
    <li>③ 회사는 보다 나은 서비스 제공을 위하여 회원의 개인정보를 제휴사에 제공할 수 있습니다. 이 경우 사전에 회원에게 제휴사, 제공되는 개인정보의 항목, 개인정보 제공 목적, 제공 기간, 개인정보 보호방안 등에 대해서 동의를 구하는 절차를 거치게 되며, 회원의 동의가 없는 경우에는 제휴사에게 이를 제공하지 않으며, 제휴관계에 변화가 있을 경우에도 이를 고지합니다. 제휴관계가 종료될 경우 제공된 개인정보에 대해서는 회원의 동의가 없는 경우 지체 없이 파기하며 개인정보 제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.</li>
</ol>


<h3>제6조 개인정보의 보유기간 및 파기</h3>
<ol>
        <li>① 회사는 이용자의 개인정보를 원칙적으로 보유·이용기간의 경과, 처리목적 달성, 서비스 이용약관에 따른 계약해지 등 개인 정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우 또는 법령에서 정보보관의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다. 다음의 경우 명시한 기간 동안 개인정보를 보유합니다.
            <table data-table_box="6">
                <tr>
                    <th>법령</th>
                    <th>항목</th>
                    <th>기간</th>
                </tr>
                <tr>
                    <td rowspan="3">전자상거래등에서의 소비자보호에 관한 법률</td>
                    <td>계약 또는 청약철회등에 관한 기록</td>
                    <td>5년</td>
                </tr>
                <tr>
                    <td>대금결제 및 재화등의 공급에 관한 기록</td>
                    <td>5년</td>
                </tr>
                <tr>
                    <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                    <td>3년</td>
                </tr>
                <tr>
                    <td>통신비밀보호법</td>
                    <td>서비스 이용 관련 개인정보(로그기록,IP등)</td>
                    <td>3개월</td>
                </tr>
                <tr>
                    <td>전자금융거래법</td>
                    <td>전자금융 거래에 관한 기록</td>
                    <td>5년</td>
                </tr>
                <tr>
                    <td>부가가치세법</td>
                    <td>부가가치세의 과세표준과 세액의 신고 자료</td>
                    <td>5년</td>
                </tr>
            </table>
        </li>
        <li>②	이용자의 동의를 받아 보유하는 개인정보에 대해 당해 이용자의 열람요구가 있는 경우 회사는 지체 없이 이를 열람, 확인할 수 있도록 조치합니다.</li>
    </ol>

<h3>제7조 휴면계정 관리</h3>

<ol>
    <li>① 회원이 1년간 서비스 이용기록이 없는 경우, 정보통신망법 제 29조에 근거하여 회원에게 사전 통지하고 별도로 분리하여 저장합니다.</li>
    <li>② 회원이 5년간 서비스 이용기록이 없는 경우, 회사는 회원가입 시 제공한 이메일로 계약해지에 대한 내용을 통보한 후 회원자격을 상실시킬 수 있으며, 이 때 회원의 모든 개인정보를 파기합니다. 단, 관계 법령의 규정에 의한 보존이 필요한 경우 관계 법령에서 규정한 기간 동안 보관합니다.</li>
</ol>

<h3>제8조 외부 링크사이트의 개인정보 수집</h3>
<p>회사 또는 판매자 회원은 다른 웹사이트에 대항 링크를 제공할 수 있습니다. 링크된 외부사이트에서 개인정보를 수집하는 행위에 대해서는 본 ‘개인정보취급방침’이 적용되지 않습니다. </p>
 

<h3>제9조 이용자의 권리와 의무</h3>
<ol>
    <li>① 이용자는 언제든지 회사의 인터넷사이트에서 자신의 개인정보를 조회하거나 수정할 수 있습니다.</li>
    <li>② 이용자가 개인정보를 조회, 수정하고자 하는 경우에는 ‘회원정보수정’을, 탈퇴하고자 하는 경우에는 ‘회원탈퇴’를 클릭하여 본인확인절차를 거친 후 직접 열람, 정정 또는 탈퇴가 가능합니다.</li>
    <li>③ 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 이용자격이 상실될 수 있습니다.</li>
    <li>④ 이용자는 비밀번호를 포함한 개인정보가 유출되지 않도록 유의하고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 주의합니다. 이용자가 위 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 관련법령에 의해 처벌받을 수 있습니다.</li>
    <li>⑤ 회사는 이용자가 타인의 개인정보를 도용하여 회원가입 등을 하였음을 알게 된 때 지체 없이 해당 아이디에 대한 회원탈퇴가 이루어질 수 있도록 안내합니다.</li>
</ol>

<h3>제10조 개인정보보호를 위한 회사의 노력</h3>

<ol>
    <li>① 회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 훼손되지 않도록 안정성 확보를 위하여 대책을 강구하고 있습니다.</li>
    <li>② 이용자의 개인정보는 비밀번호에 의해 보호되며 중요 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등 별도 보안기능을 통해 보호하고 있습니다.</li>
    <li>③ 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출, 훼손되는 것을 막기 위해 자료를 수시로 백업하고 있으며 보안에 최선을 다하여 자료가 누출되거나 손상되지 않도록 방지하고 있습니다.</li>
    <li>④ 이용자의 이메일주소 및 비밀번호는 원칙적으로 본인만이 사용하도록 되어 있습니다. 회사는 이용자의 개인적인 부주의로 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.</li>
</ol>

<h3>제11조 개인정보관리책임자</h3>

<p>회사의 개인정보 처리에 관한 업무를 총괄하고 개인정보 처리와 관련한 고객님의 불만처리 및 피해구제등을 위하여 아래와 같이 개인정보관리책임자를 지정하고 있습니다.  </p>

<p>
    <span>[개인정보관리책임자]</span><br>
    성명: 김별<br>
    소속: 쓰리랩스(주)<br>
    연락처: 02-3273-2869 b.kim@marketbang.kr<br>
</p>
<br>
<p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br>
－ 개인정보침해신고센터(privacy.kisa.or.kr/국번없이118)<br>
－ 대검찰청사이버수사과(www.spo.go.kr/국번없이 1301)<br>
－ 경찰청사이버안전국(www.ctrc.go.kr/국번없이182)<br>
</p>

<h3>제12조 고지의 의무</h3>

<p>개인정보취급방침은 법령, 정부의 정책 또는 회사 내부정책등에 의해 변경될 수 있습니다. 수정 및 변경이 있을시에는 홈페이지를 통해 공지합니다.</p>


<p>
    2024년 7월 23일 공고
    <br/>
    2024년 7월 30일 시행
</p>

</article>
</template>
<style scoped lang="sass">
article
    padding: 16px
    height: 80vh
    overflow-y: scroll
    h1
        font-size: 16px
        font-weight: bold
        padding: 
            bottom: 20px
    h2
        font-size: 14px
        padding: 
            top: 18px
        color: #911054
    h3
        font-size: 14px
        padding: 
            top: 12px
    h2, h3
        font-weight: bold
        padding: 
            bottom: 12px
    p
        font-size: 14px
        line-height: 22px
        padding: 
            bottom: 10px
        span
            font-weight: bold
    [data-date_row]
        font-size: 12px
        padding: 
            top: 30px
    & > ol
        list-style: none
        li
            font-size: 14px
            line-height: 22px
            padding:
                left: 12px
                bottom: 10px
            text-indent: -17px
            &:nth-child(n+10)
                padding-left: 12px
                text-indent: -20px
            span
                font-weight: bold
            ol
                padding-top: 10px
                li
                    padding:
                        left: 12px
                        bottom: 5px
                    text-indent: -16px
            [data-table_box]
                width: 100%
                margin-top: 15px
                border-collapse: collapse
                box-sizing: border-box
                border: 1px solid #222
                tr
                    border: 1px solid #222
                    th
                        width: 50%
                        height: 30px
                        text-align: center
                        line-height: 30px
                        font-weight: bold
                        background-color: #eeeeee
                        border: 1px solid #222
                    td
                        padding: 1rem
                        border: 1px solid #222
                        display: table-cell
                        vertical-align: middle
            [data-table_box="6"]
                th
                    &:first-of-type
                        width: 35%
                    &:nth-of-type(2)
                        width: 45%
                td
                    border: 0.1rem solid #222
                    &:last-of-type
                        text-align: center
</style>